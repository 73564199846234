<template>
  <div>
    <div class="login">
      <Modal
        class-name="login_modal"
        v-model="showModal"
        :mask-closable="false"
        width="360"
        :footer-hide="true"
      >
        <Tabs :animated="false" v-show="activeTabName == 'login'">
          <TabPane label="账号密码登录">
            <Form
              :model="formLogin"
              ref="formLogin"
              :rules="ruleSmsValidate"
              label-position="top"
            >
              <FormItem prop="phone">
                <Input
                  v-model="formLogin.phone"
                  clearable
                  placeholder="请输入账号"
                ></Input>
              </FormItem>

              <FormItem prop="password">
                <Input
                  v-model="formLogin.password"
                  :password="true"
                  type="password"
                  placeholder="请输入密码"
                ></Input>
              </FormItem>
              <span class="forgetPassword" 
                ><span  @click="returnForget">忘记密码？</span> </span
              >
            </Form>
            <Button
              class="loginButton"
              size="large"
              :loading="loadingbtn"
              style="width: 100%"
              type="primary"
              @click="sumPasswordLogin"
              >登录</Button
            >
            <div style="text-align: center">
              <span class="register" @click="returnRegister">注册账号</span>
            </div>
          </TabPane>
          <TabPane label="手机号快捷登录">
            <Form
              :model="formSmsLogin"
              ref="formSmsLogin"
              :rules="ruleSmsValidate"
              label-position="top"
            >
              <FormItem prop="phone">
                <Input
                  v-model="formSmsLogin.phone"
                  clearable
                  placeholder="请输入手机号码"
                ></Input>
              </FormItem>
              <Row :gutter="5" style="height: 70px">
                <Col span="16" style="height: 70px">
                  <FormItem prop="smsCode">
                    <Input
                      v-model="formSmsLogin.smsCode"
                      clearable
                      maxlength="6"
                      placeholder="请输入验证码"
                    ></Input>
                  </FormItem>
                </Col>
                <Col span="8" style="line-height: 100%">
                  <Button
                    :disabled="!canGetSmsCode"
                    :loading="msgLoadingBtn"
                    @click="getSmsCode"
                    style="
                      width: 100%;
                      margin-top: 1.38px;
                      padding: 0px;
                      overflow: hidden;
                    "
                    ><span class="btn-sms-code">{{ smsCodeText }}</span></Button
                  >
                </Col>
              </Row>
            </Form>
            <Button
              class="loginButton"
              size="large"
              :loading="loadingbtn"
              style="width: 100%"
              type="primary"
              @click="sumCodeLogin"
              >登录</Button
            >
          </TabPane>
        </Tabs>
        <div v-show="activeTabName == 'register'">
          <div class="login_title">注册账号</div>
          <Form
            :model="formRegister"
            ref="formRegister"
            :rules="ruleSmsValidate"
            label-position="top"
          >
            <FormItem prop="phone">
              <Input
                v-model="formRegister.phone"
                clearable
                placeholder="请输入手机号码"
              ></Input>
            </FormItem>
            <FormItem prop="password">
              <Input
                v-model="formRegister.password"
                :password="true"
                type="password"
                placeholder="请输入密码"
              ></Input>
            </FormItem>
            <FormItem prop="confirmRegisPassword">
              <Input
                v-model="formRegister.confirmRegisPassword"
                :password="true"
                type="password"
                placeholder="请确认密码"
              ></Input>
            </FormItem>
            <Row :gutter="5" style="height: 70px">
              <Col span="16" style="height: 70px">
                <FormItem prop="smsCode">
                  <Input
                    v-model="formRegister.smsCode"
                    maxlength="6"
                    placeholder="请输入验证码"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="8" style="line-height: 100%">
                <Button
                  :disabled="!canGetSmsCode"
                  :loading="msgLoadingBtn"
                  @click="getRegisSmsCode"
                  style="
                    width: 100%;
                    margin-top: 1.38px;
                    padding: 0px;
                    overflow: hidden;
                  "
                  ><span class="btn-sms-code">{{
                    smsCodeTextRegis
                  }}</span></Button
                >
              </Col>
            </Row>
          </Form>
          <Button
            class="loginButton"
            size="large"
            :loading="loadingbtn"
            style="width: 100%"
            type="primary"
            @click="registerBtn"
            >注册账号</Button
          >
          <div style="text-align: center">
            <span class="register" @click="returnLogin">返回登录</span>
          </div>
        </div>
        <div v-show="activeTabName == 'resetPassword'">
          <div class="login_title">重置密码</div>
          <Form
            :model="formResetPassword"
            ref="formResetPassword"
            :rules="ruleSmsValidate"
            label-position="top"
          >
            <FormItem prop="phone">
              <Input
                v-model="formResetPassword.phone"
                clearable
                placeholder="请输入手机号码"
              ></Input>
            </FormItem>
            <FormItem prop="password">
              <Input
                v-model="formResetPassword.password"
                :password="true"
                type="password"
                placeholder="请输入密码"
              ></Input>
            </FormItem>
            <FormItem prop="confirmPassword">
              <Input
                v-model="formResetPassword.confirmPassword"
                :password="true"
                type="password"
                placeholder="请确认密码"
              ></Input>
            </FormItem>
            <Row :gutter="5" style="height: 70px">
              <Col span="16" style="height: 70px">
                <FormItem prop="smsCode">
                  <Input
                    v-model="formResetPassword.smsCode"
                    clearable
                    maxlength="6"
                    placeholder="请输入验证码"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="8" style="line-height: 100%">
                <Button
                  :disabled="!canGetSmsCode"
                  :loading="msgLoadingBtn"
                  @click="getResetSmsCode()"
                  style="
                    width: 100%;
                    margin-top: 1.38px;
                    padding: 0px;
                    overflow: hidden;
                  "
                  ><span class="btn-sms-code">{{
                    smsCodeTextReset
                  }}</span></Button
                >
              </Col>
            </Row>
          </Form>
          <Button
            class="loginButton"
            size="large"
            :loading="loadingbtn"
            style="width: 100%"
            type="primary"
            @click="resetPasswordBtn"
            >重置密码</Button
          >
          <div style="text-align: center">
            <span class="register" @click="returnLogin">返回登录</span>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import {
  findPhone,
  registerPhone,
  gptRegisSms,
  loginPhone,
  gptResetSms,
  gptMemberPasswordGetBack,
  gptMemberLogin,
  gptMemberRegist,checkKeyToken,
} from "@/api/api.js";
// import { Form,Input } from "view-design";
import { setStore } from "@/libs/storage";
import Cookies from "js-cookie";
// import wx from "weixin-webview-jssdk";


export default {
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    const validateConfirmPass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码长度不得小于6位"));
      } else if (value !== this.formResetPassword.password) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    const validateRegisConfirmPass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码长度不得小于6位"));
      } else if (value !== this.formRegister.password) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      userInfo: {
        id: "682265633886208",
        parentId: "",
        createBy: "",
        createTime: "2022-03-17 10:45:12",
        updateBy: "admin",
        updateTime: "2023-03-23 18:54:47",
        delFlag: 0,
        openStatus: 1,
        sortOrder: null,
        username: "admin",
        password: null,
        nickname: "管理员",
        mobile: "17674164497",
        email: "1ojian410@163.com",
        address: "",
        street: "",
        sex: "男",
        passStrength: "弱",
        avatar: "http://i.loli.net/2019/04/28/5cc5a71a6e3b6.png",
        type: 0,
        status: 0,
        description: "",
        departmentId: "1364138936531816448",
        departmentTitle: "",
        teamId: "1559156235452944384",
        birth: null,
      },
      loadingbtn: false,
      msgLoadingBtn: false,
      showModal: false,
      activeTabName: "login",
      formLogin: {
        phone: "",
        password: "",
        captcha: "",
      },
      phoneList: ["17674164497"],
      captchaUrl: "",
      formRegister: {
        phone: "",
        password: "",
        confirmRegisPassword: "",
        smsCode: "",
        timer: null,
        countDown: 60,
      },
      formResetPassword: {
        phone: "",
        password: "",
        confirmPassword: "",
        smsCode: "",
        timer: null,
        countDown: 60,
      },
      formSmsLogin: {
        phone: "",
        smsCode: "",
        timer: null,
        countDown: 60,
      },
      ruleSmsValidate: {
        phone: [
          { required: true, trigger: "blur", message: "请输入手机号" },
          { validator: checkPhone, trigger: "blur" },
        ],
        smsCode: [
          { required: true, trigger: "blur", message: "请输入6位验证码" },
        ],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
        confirmPassword: [
          {
            required: true,
            trigger: "blur",
            message: "请确认密码",
          },
          { validator: validateConfirmPass, trigger: "blur" },
        ],
        confirmRegisPassword: [
          {
            required: true,
            trigger: "blur",
            message: "请确认密码",
          },
          { validator: validateRegisConfirmPass, trigger: "blur" },
        ],
      },
      // ruleLoginValidate: {
      //   username: [
      //     { required: true, trigger: "blur", message: "请输入用户名" },
      //   ],
      //   password: [
      //     { required: true, trigger: "blur", message: "请输入用户密码" },
      //   ],
      //   captcha: [{ required: true, trigger: "blur", message: "请输入验证码" }],
      // },
    };
  },
  methods: {
    showLoginBox() {
      this.showModal = true;
    },
    returnLogin() {
      this.initForm();
      this.activeTabName = "login";
    },
    returnRegister() {
      this.initForm();
      this.activeTabName = "register";
    },
    returnForget() {
      this.initForm();
      this.activeTabName = "resetPassword";
    },

    refreshCaptcha() {
      this.captchaUrl = "/api/captcha?" + Date.now();
    },
    getResetSmsCode() {
      console.log("获取重置密码验证码");
      this.msgLoadingBtn = true;
      this.$refs.formResetPassword.validateField("phone", (v) => {
        if (v) {
          this.msgLoadingBtn = false;
        } else {
          findPhone({ mobile: this.formResetPassword.phone }).then((res) => {
            this.loadingbtn = false;
            this.msgLoadingBtn = false;
            if (res.success) {
              // 获取验证码
              // gptResetSms(this.formResetPassword.phone).then((response) => {
              //   console.log(response, "");
              // });
              this.sumcode("reset");
            } else {
              if (res.request && res.request.timeout === 15000) {
                // this.$Message.error("请求超时");
              } else {
                this.$Modal.confirm({
                  title: "该手机号尚未注册",
                  onOk: () => {},
                });
              }
            }
          });
        }
      });
    },
    getRegisSmsCode() {
      this.msgLoadingBtn = true;
      this.$refs.formRegister.validateField("phone", (v) => {
        if (v) {
          this.msgLoadingBtn = false;
        } else {
          findPhone({ mobile: this.formRegister.phone }).then((res) => {
            this.loadingbtn = false;
            this.msgLoadingBtn = false;
            if (res.success) {
              this.$Message.error("账号已存在");
            } else {
              if (res.request && res.request.timeout === 15000) {
                // this.$Message.error("请求超时");
              } else {
                this.sumcode("regis");
              }
            }
          });
        }
      });
    },
    getSmsCode() {
      this.msgLoadingBtn = true;
      this.$refs.formSmsLogin.validateField("phone", (v) => {
        if (v) {
          this.msgLoadingBtn = false;
        } else {
          findPhone({ mobile: this.formSmsLogin.phone }).then((res) => {
            this.loadingbtn = false;
            this.msgLoadingBtn = false;
            if (res.success) {
              //等待接口
              // if(this.phoneList.includes(this.formSmsLogin.phone)){
              // if (true) {
              this.sumcode();
            } else {
              if (res.request && res.request.timeout === 15000) {
                // this.$Message.error("请求超时");
              } else {
                this.$Modal.confirm({
                  title: "将使用该手机号注册并登录",
                  onOk: () => {
                    this.sumcode();
                  },
                });
              }
            }
          });
        }
      });
    },
    sumcode(type) {
      switch (type) {
        case "regis":
          this.formRegister.timer = setInterval(() => {
            this.formRegister.countDown--;
            if (this.formRegister.countDown <= 0) {
              clearInterval(this.formRegister.timer);
              this.formRegister.countDown = 60;
            }
          }, 1000);
          this.phoneList.push(this.formRegister.phone);
          //发送验证码
          gptRegisSms(this.formRegister.phone).then((res) => {
            console.log(res);
          });
          break;
        case "reset":
          this.formResetPassword.timer = setInterval(() => {
            this.formResetPassword.countDown--;
            if (this.formResetPassword.countDown <= 0) {
              clearInterval(this.formResetPassword.timer);
              this.formResetPassword.countDown = 60;
            }
          }, 1000);
          this.phoneList.push(this.formResetPassword.phone);
          //发送验证码
          gptResetSms(this.formResetPassword.phone).then((res) => {
            console.log(res);
          });
          break;
        default:
          this.formSmsLogin.timer = setInterval(() => {
            this.formSmsLogin.countDown--;
            if (this.formSmsLogin.countDown <= 0) {
              clearInterval(this.formSmsLogin.timer);
              this.formSmsLogin.countDown = 60;
            }
          }, 1000);
          this.phoneList.push(this.formSmsLogin.phone);
          //发送验证码
          registerPhone(this.formSmsLogin.phone).then((res) => {
            console.log(res);
          });
          break;
      }
    },
    // 密码登录
    sumPasswordLogin() {
      this.userInfo.mobile = this.formLogin.phone;
      this.$refs.formLogin.validate((valid) => {
        if (valid) {
          //虚拟的
          this.loadingbtn = true;
          gptMemberLogin({
            mobile: this.formLogin.phone,
            password: this.formLogin.password,
          }).then((res) => {
            if (res.success) {
              let dcs = {};
              dcs.accessToken = res.result.token;
              dcs.mobile = this.formLogin.phone;
              console.log(dcs);
              Cookies.set("userInfo", JSON.stringify(dcs), {
                expires: 7,
              });
              Cookies.set("accessToken", res.result.token);
              Cookies.set("noticeShowed", "noticeShowed");
              this.loadingbtn = false;
              this.showModal = false;
              this.initForm();
              clearInterval(this.formLogin.timer);
              this.formLogin.countDown = 60;
              this.$emit("loginhome", dcs);
              setStore("accessToken", res.result.token);
              this.$Message.success("登录成功");
              //
              this.checkKey(res.result.token);
              this.initForm();
            } else {
              this.$Message.error(res.message);
              this.loadingbtn = false;
            }
          });
        } else {
          // this.$Message.warning("请检查账号或者密码是否正确");
          // this.loadingbtn = false;
        }
      });
    },
    // 短信登录
    sumCodeLogin() {
      this.userInfo.mobile = this.formSmsLogin.phone;
      this.$refs.formSmsLogin.validate((valid) => {
        if (valid) {
          //虚拟的
          this.loadingbtn = true;
          loginPhone({
            mobile: this.formSmsLogin.phone,
            code: this.formSmsLogin.smsCode,
          }).then((res) => {
            if (res.success) {
              this.$Message.success("登录成功");
              let dcs = {};
              let xbtoken = "XBOOT_TOKEN_MEMBER_PRE:" + res.result.token;
              dcs.accessToken = xbtoken;
              dcs.mobile = this.formSmsLogin.phone;
              console.log(dcs);
              Cookies.set("userInfo", JSON.stringify(dcs), {
                expires: 7,
              });
              Cookies.set("accessToken", xbtoken);
              Cookies.set("noticeShowed", "noticeShowed");
              this.loadingbtn = false;
              this.showModal = false;
              this.formSmsLogin.phone = "";
              this.formSmsLogin.smsCode = "";
              clearInterval(this.formSmsLogin.timer);
              this.formSmsLogin.countDown = 60;
              this.$emit("loginhome", dcs);
              setStore("accessToken", xbtoken);
              //
              this.checkKey(xbtoken);
              this.initForm();
            } else {
              this.$Message.warning("请检查数据验证码或手机号是否正确");
              this.loadingbtn = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 查询会员信息
    checkKey(token) {
      checkKeyToken(token)
      // fetch(`http://101.200.140.188:38892/check_key/${token}`)
      .then((response) => {
          // infoRes.json().then((response) => {
            if (response.exists !== undefined) {
              this.$emit("changeFlag", {
                vip: response.vip,
                id: response.id,
              });
              setStore("vip", response.vip);
              setStore("id", response.id);
              setStore("uid", response.username);
              setStore("cur", response.cur);
            } else {
              this.$Message.error("登录过期，请重新登录");
              this.vip = "";
              this.isId = "";
              Cookies.remove("userInfo");
              Cookies.remove("accessToken");
              setStore("accessToken", "");
              setStore("vip", "");
              // this.$router.go(0);
            }
          // });
        }
      );
    },

    // 注册按钮
    registerBtn() {
      this.$refs.formRegister.validate((valid) => {
        if (valid) {
          let params = {
            mobile: this.formRegister.phone,
            password: this.formRegister.password,
            sendRegistSmsCode: this.formRegister.smsCode,
          };
          gptMemberRegist(params).then((res) => {
            console.log(res, "注册返回值");
            if (res.success) {
              this.$Message.success(res.message);
              this.returnLogin();
            } else {
              this.$Message.error(res.message);
            }
          });
        }
      });
    },
    // 重置密码
    resetPasswordBtn() {
      this.$refs.formResetPassword.validate((valid) => {
        if (valid) {
          let params = {
            mobile: this.formResetPassword.phone,
            newPassword: this.formResetPassword.password,
            sendGetBackSmsCode: this.formResetPassword.smsCode,
          };
          gptMemberPasswordGetBack(params).then((res) => {
            if (res.success) {
              this.$Message.success(res.message);
              this.returnLogin();
            } else {
              this.$Message.error(res.message);
            }
          });
        }
      });
    },
    SMsubmit() {
      this.$refs.formSmsLogin.validate((valid) => {
        if (valid) {
          this.showModal = false;
          this.activeTabName = "login";
          this.formLogin.username = "";
          this.formLogin.password = "";
          this.formLogin.captcha = "";
          this.refreshCaptcha();
          this.formRegister.username = "";
          this.formRegister.password = "";
          this.formRegister.confirmPassword = "";
          this.formRegister.phone = "";
          this.formSmsLogin.phone = "";
          this.formSmsLogin.smsCode = "";
          clearInterval(this.formSmsLogin.timer);
          this.formSmsLogin.countDown = 60;
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.showModal = false;
      this.activeTabName = "login";
      this.formLogin.username = "";
      this.formLogin.password = "";
      this.formLogin.captcha = "";
      this.refreshCaptcha();
      this.formRegister.username = "";
      this.formRegister.password = "";
      this.formRegister.confirmPassword = "";
      this.formRegister.phone = "";
      this.formSmsLogin.phone = "";
      this.formSmsLogin.smsCode = "";
      clearInterval(this.formSmsLogin.timer);
      this.formSmsLogin.countDown = 60;
    },
    initForm() {
      clearInterval(this.formResetPassword.timer);
      clearInterval(this.formRegister.timer);
      clearInterval(this.formSmsLogin.timer);
      this.formLogin = {
        phone: "",
        password: "",
        captcha: "",
      };
      this.formRegister = {
        phone: "",
        password: "",
        confirmRegisPassword: "",
        smsCode: "",
        timer: null,
        countDown: 60,
      };
      this.formResetPassword = {
        phone: "",
        password: "",
        confirmPassword: "",
        smsCode: "",
        timer: null,
        countDown: 60,
      };
      this.formSmsLogin = {
        phone: "",
        smsCode: "",
        timer: null,
        countDown: 60,
      };
    },
  },
  computed: {
    canGetSmsCode() {
      return this.formSmsLogin.countDown === 60;
    },
    smsCodeText() {
      if (this.canGetSmsCode) {
        return "获取验证码";
      } else {
        return `${this.formSmsLogin.countDown}秒后重试`;
      }
    },
    canGetSmsCodeRegis() {
      return this.formRegister.countDown === 60;
    },
    smsCodeTextRegis() {
      if (this.canGetSmsCodeRegis) {
        return "获取验证码";
      } else {
        return `${this.formRegister.countDown}秒后重试`;
      }
    },
    canGetSmsCodeReset() {
      return this.formResetPassword.countDown === 60;
    },
    smsCodeTextReset() {
      if (this.canGetSmsCodeReset) {
        return "获取验证码";
      } else {
        return `${this.formResetPassword.countDown}秒后重试`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  .btn-login {
    cursor: pointer;
    font-weight: bold;
    color: #1890ff;
  }

  .captcha {
    height: 36px;
    vertical-align: middle;
  }
}
.register {
  color: #004fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.ivu-modal-header p,
/deep/ .ivu-modal-header-inner {
  font-weight: bold;
  text-align: center;
  font-size: 22px;
}
.loginButton {
  background: linear-gradient(135deg, #808bff 0%, #2e67e6 100%);
  border: 0px;
  border-radius: 8px;
  font-weight: 700;
}

.login_modal {
  .ivu-modal {
    .ivu-modal-content {
      .ivu-modal-footer {
        border-top: 0px;
      }
      .ivu-modal-body {
        padding: 26px;
      }
    }
  }
}
.login_title {
  width: 100%;

  font-size: large;
  font-weight: bolder;
  margin-bottom: 0.2rem;
}
.login_footer {
  text-align: center;
  display: none;
}
.btn-sms-code {
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  font-weight: 700;
  color: #004fff;
}
.forgetPassword {

  
  color: #004fff;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -22px;
  span{
    cursor: pointer;
  }
}
</style>
