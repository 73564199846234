import axios from 'axios';
import { getStore, setStore } from './storage';
import { router } from '../router';
import { Message } from 'view-design';
import Cookies from 'js-cookie';
import wx from 'weixin-webview-jssdk';
// var bodyParser = require('body-parser');
// console.log(bodyParser,"body parse是个啥")
let baseApi = "/xboot";
axios.defaults.timeout = 15000;

axios.interceptors.request.use(config => {
    return config;
}, err => {
    Message.error('请求超时');
    return Promise.resolve(err);
});

// http response 拦截器
axios.interceptors.response.use(response => {
    const data = response.data;
    // 根据返回的code值来做不同的处理(和后端约定)
    switch (data.code) {

        case 401:
            // 未登录 清除已登录状态
            Cookies.set('userInfo', '');
            setStore('accessToken', '');
            if (router.history.current.name != "/") {
                if (data.message !== null) {
                    Message.error(data.message);
                } else {
                    Message.error("未知错误，请重新登录");
                }
                wx.miniProgram.navigateTo({
                    url: "/pages/login_wx/login_wx?relogin=true",
                });
            }else{
                location.reload()
            }

            break;
        case 403:
            // 没有权限
            if (data.message !== null) {
                Message.error(data.message);
            } else {
                Message.error("未知错误");
            }
            break;
        case 500:
            // 错误
            if (data.message !== null) {
                // Message.error(data.message);
            } else {
                Message.error("未知错误");
            }
            break;
        default:
            return data;
    }

    return data;
}, (err) => {
    // 返回状态码不为200时候的错误处理
    Message.error(err.toString());
    return Promise.resolve(err);
});

export const getRequest = (url, params) => {
    let accessToken = getStore('accessToken');
    return axios({
        method: 'get',
        url: `${baseApi}${url}`,
        params: params,
        headers: {
            'accessToken': accessToken
        }
    });
};
export const getRequestCheck = (url, params) => {
    let accessToken = getStore('accessToken');
    return axios({
        method: 'get',
        url: `/check/${url}`,
        params: params,
        headers: {
            'accessToken': accessToken
        }
    });
};
export const getRequestNoAccess = (url, params) => {
    // let accessToken = getStore('accessToken');
    let accessToken =  Cookies.get('accessToken');
    console.log(accessToken,"小程序传过来的token")
    return axios({
        method: 'get',
        url: `${baseApi}${url}`,
        params: params,
        headers: {
            'token': accessToken
        }
    });
};
export const postRequest = (url, params) => {
    let accessToken = getStore("accessToken");
    return axios({
        method: 'post',
        url: `${baseApi}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accessToken': accessToken
        }
    });
};
export const postRequestNoAccess = (url, params) => {
    let accessToken = getStore("accessToken");
    return axios({
        method: 'post',
        url: `${baseApi}${url}`,
        data: params,
        headers: {
            "Content-Type": "multipart/form-data",
            'token': accessToken
        }
    });
};
export const postFormDataRequest = (url, params) => {
    let accessToken = getStore("accessToken");
    return axios({
        method: 'post',
        url: `${baseApi}${url}`,
        data: params,
        headers: {
            "Content-Type": "multipart/form-data",
            'accessToken1': accessToken
        }
    });
};

export const putRequest = (url, params) => {
    let accessToken = getStore("accessToken");
    return axios({
        method: 'put',
        url: `${baseApi}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accessToken': accessToken
        }
    });
};

export const postBodyRequest = (url, params) => {
    let accessToken = getStore('accessToken');
    return axios({
        method: 'post',
        url: `${baseApi}${url}`,
        data: params,
        headers: {
            'accessToken': accessToken
        }
    });
};

/**
 * 无需token验证的GET请求 避免旧token过期导致请求失败
 * @param {*} url 
 * @param {*} params 
 */
export const getNoAuthRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${baseApi}${url}`,
        params: params
    });
};

export const postNoAuthRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${baseApi}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};
export const postRequestZG_7b = (url,params)=>{
    return axios({
        method:'POST',
        url:`/chat6606${url}`,
        headers: {
          "Content-Type": "application/json",
          'Accept': "text/event-stream",
        },
        mode: "cors",
        responseType: 'stream',
        data: params,
        timeout:999999999,

    })
}
export const postRequestZG = (url,params,chatToken)=>{
    return axios({
        method:'POST',
        url:`/chat8707${url}`,
        headers: {
          "Content-Type": "application/json",
          'Accept': "text/event-stream",
          'Accesstoken': chatToken,
        },
        mode: "cors",
        responseType:'stream',
        data: params,
        timeout:999999999,
    })
}

