<template>
  <div class="chat_history_containner">
    <div class="chat_history_list_footer">
      <Button class="newChatBtn" @click="openNewChat">新的对话</Button>
      <!-- <span></span> -->
    </div>
    <Divider style="border-bottom: 1px solid #dddddd" />
    <div class="chat_history_list" ref="scrollContainer" @scroll="handleScroll">
      <div
        :class="
          current.index == cIndex
            ? 'chat_history_item chat_history_item_selected'
            : 'chat_history_item'
        "
        @click="showHistory(cItem, cIndex)"
        v-for="(cItem, cIndex) in historyList"
        :key="cIndex"
      >
        <div class="chat_history_item_title">
          <img src="@/assets/talk.png" alt="" />
          {{ getParamValue(cItem, "title") }}
        </div>
        <!-- <p>
          <span class="chat_history_item_time">{{
            getParamValue(cItem, "time")
          }}</span>
          <span>共{{ getParamValue(cItem, "pice") }}条</span>
        </p> -->
        <!-- <img
          src="../../assets/export_history.png"
          title="导出记录"
          class="exportHistory"
          @click.stop="convertArrayToMd(cItem, cIndex)"
        /> -->

        <img
          src="../../assets/delete.png"
          title="删除记录"
          class="deleteHistory"
          @click.stop="deleteHistory(cItem, cIndex)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Card, Button, Divider } from "view-design";
import { getGptHistory, deleteHistory } from "@/api/api";
export default {
  components: {
    Card,
    Button,
  },
  data() {
    return {
      historyList: [],
      current: {
        index: null,
      },
      hasMore: false, // 是否还有更多数据
      page: 1, // 当前页码
      pageSize: 20, // 每页显示数量
    };
  },
  methods: {
    initHistory() {
      this.historyList = [];
      // this.current.index = null;
      this.hasMore = false;
      (this.page = 1), (this.pageSize = 20);
      this.getGptHistory();
    },
    openNewChat() {
      if (
        this.historyList.length == 0 ||
        this.historyList[0].historys.length == 0
      ) {
        this.$Message.info("当前已是新的对话");
      } else {
        this.historyList.unshift({
          id: "newchat",
          historys: [],
        });
        this.current = {
          index: 0,
        };
        this.$emit("setCurrentListHistory", { name: "historyList", value: [] });
        this.$emit("setCurrentListHistory", { name: "currentId", value: "" });
      }
      // 触发父组件的清空方法，回到defaultList；
    },
    showHistory(data, index) {
      this.current.index = index;
      // 处理好数据后，添加到父组件的 currentListHistory
      let array = [];
      data.historys.forEach((element) => {
        array.push({
          question: element.question,
          answer: element.answer,
          status: 2,
          agree: false,
          disagree: false,
          // speak: false,
          // pause: false,
          loading: false,
          time: this.protoTime(element.time),
        });
      });

      this.$emit("setCurrentListHistory", {
        name: "historyList",
        value: array,
      });
      this.$emit("setCurrentListHistory", {
        name: "currentId",
        value: data.id,
      });
    },

    // 删除聊天记录
    deleteHistory(data, index) {
      this.showHistory(data, index);
      // data.id  走接口
      this.$Modal.confirm({
        title: "删除",
        content: `<p>确认删除此条记录？</p>`,
        loading: true,
        onOk: () => {
          let params = { id: data.id };
          deleteHistory(params).then((res) => {
            if (res.success) {
              setTimeout(() => {
                this.$Modal.remove();
                this.$Message.info(res.message);
                this.openNewChat();
                this.initHistory();
              }, 1000);
            }
          });
        },
      });
    },
    // 导出md文件
    convertArrayToMd(chatHistory) {
      let mdContent = "# 对话记录\n\n";
      chatHistory.historys.forEach((chat) => {
        mdContent += `Role: ${chat.question}\n\nZhiGong: ${chat.answer}\n\n`;
      });
      console.log(mdContent, "");
      const blob = new Blob([mdContent], { type: "text/plain;charset=utf-8" });
      let time = `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDay()}_${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
      FileSaver.saveAs(blob, `ZhiGong${time}.md`);
      // this.$Message.success("导出成功")
    },
    // 获取title
    getParamValue(data, type) {
      switch (type) {
        case "title":
          if (data.historys.length > 0) return data.historys[0]["question"];
          else return "新的对话";
        case "time":
          if (data.historys.length > 0)
            return this.protoTime(data.historys[0]["time"]);
          else return this.protoTime(new Date().valueOf());
        case "pice":
          if (data.historys) return data.historys.length;
          else return 0;
        default:
          break;
      }
    },

    // 查询历史记录
    getGptHistory() {
      let params = {
        order: "desc",
        pageNumber: this.page,
        pageSize: this.pageSize,
      };
      getGptHistory(params).then((res) => {
        if (res.success) {
          if (
            res.result.content !== undefined &&
            res.result.content.length >= 20
          ) {
            this.page = this.page + 1;
            this.hasMore = true;
          } else {
            this.hasMore = false;
          }
          let array = [];
          res.result.content.forEach((item) => {
            array.push({
              id: item.id,
              historys: JSON.parse(item.historys),
            });
          });
          this.historyList = this.historyList.concat(array);
        }
      });
    },

    // 时间戳转 标准时间
    protoTime(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      const standardTime =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return standardTime;
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      const scrollTop = container.scrollTop;
      const containerHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;
      // 判断是否滚动到底部
      if (scrollTop + containerHeight >= scrollHeight) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMore) {
        this.getGptHistory();
      }
    },
  },
  mounted() {
    this.getGptHistory();
  },
};
</script>

<style lang="less">
.chat_history_containner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  .chat_history_list {
    // height: 80vh;
    overflow-y: scroll;
  }
  .chat_history_item {
    padding: 10px;
    margin: 5px 0;
    border-radius: 10px;
    // height: 70px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    .chat_history_item_title {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      img {
        width: 18px;
      }
    }
    p {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .deleteHistory {
      border-radius: 50% 50%;
      background-color: #fff;
      position: absolute;
      right: 10px;
      // bottom: 20px;
      width: 20px;
      // height: 20px;
      display: none;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .exportHistory {
      border-radius: 50% 50%;
      background-color: #fff;
      position: absolute;
      right: 30px;
      // bottom: 10px;
      width: 20px;
      display: none;
      img {
        // position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
  .chat_history_item:hover {
    padding-right: 50px;
    .deleteHistory {
      display: block;
    }
    .exportHistory {
      display: block;
    }
    background-color: rgba(255, 255, 255, 0.9);
  }
  .chat_history_item_selected {
    // border: 2px solid #4773ee;
    background-color: rgba(255, 255, 255, 0.9);
  }
  .chat_history_list_footer {
    padding-top: 20px;
  }
  .newChatBtn {
    width: 50%;
    min-height: 40px;
    font-size: 16px;
    color: #797eff;
    background-color: #ece9ec;
    border: 1px solid #1b44e2;
    font-weight: bold;
    border-radius: 7px;
  }
  .newChatBtn:hover {
    border: 0px;
    background-image: linear-gradient(139deg, rgb(195, 160, 255), #6d8bff);
    color: #fff;
  }
}
</style>
