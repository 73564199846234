import { render, staticRenderFns } from "./chatPage.vue?vue&type=template&id=0ef96d42&scoped=true&"
import script from "./chatPage.vue?vue&type=script&lang=js&"
export * from "./chatPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef96d42",
  null
  
)

export default component.exports