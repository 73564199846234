import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from './libs/axios.js'
import { setStore, getStore, removeStore } from './libs/storage.js'
// in main.js
import VueSSE from "vue-sse";
import { addListener, launch } from 'devtools-detector';

// using defaults
Vue.use(VueSSE);
Vue.use(ViewUI);
Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.postBodyRequest = postBodyRequest;
Vue.prototype.getNoAuthRequest = getNoAuthRequest;
Vue.prototype.postNoAuthRequest = postNoAuthRequest;
Vue.prototype.setStore = setStore;
Vue.prototype.getStore = getStore;
Vue.prototype.removeStore = removeStore;
Vue.config.productionTip = false

//  禁止 debugger ；
// addListener(
//   (isOpen) => {
//     if(isOpen==true){
//       // debugger;
//       location.href = 'about:blank';
//     }
//   }
// )
// launch();
// window.console.log = ()=>{};
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
