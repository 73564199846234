import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChatView from '../views/chatPage/chatPage.vue'
import ChatView6606 from '../views/chatPage6606/chatPage.vue'
import ChatView6606_7b from '../views/chatPage6606_7b/chatPage.vue'
import HomeViewApp from '../views/webapp/HomeView.vue'
import ChatViewApp from '../views/webapp/chatPage/chatPage.vue'
import ChatView6606App from '../views/webapp/chatPage6606/chatPage.vue'
import ChatView6606App_7b from '../views/webapp/chatPage6606_7b/chatPage.vue'
import UserInfo from '../views/webapp/userInfo'
// import loginin from '../components/loginpage.vue'
// import dialogue from '@/components/dialogue'
// import loginstatic from '@/components/loginstatic'
import registers from '@/components/register/registers'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/register',
    name: 'register',
    // component: () => import(/* webpackChunkName: "register" */ '../components/register/registers.vue')
    component:registers
  },
  {
    path: '/chatWithZG',
    name: 'chatWithZG',
    // component: () => import(/* webpackChunkName: "register" */ '../components/register/registers.vue')
    component:ChatView
  },
  {
    path: '/13B',
    name: '13B',
    // component: () => import(/* webpackChunkName: "register" */ '../components/register/registers.vue')
    component:ChatView6606
  },
  {
    path: '/7B',
    name: '7B',
    // component: () => import(/* webpackChunkName: "register" */ '../components/register/registers.vue')
    component:ChatView6606_7b
  },
  {
    path: '/webapp',
    name: 'webapp',
    component: HomeViewApp,
  },
  {
    path: '/chatWithZGApp',
    name: 'chatWithZGApp',
    component: ChatViewApp,
  },
  {
    path: '/13bApp',
    name: 'ChatView6606App',
    component: ChatView6606App,
  },
  {
    path: '/7bApp',
    name: 'ChatView6606App',
    component: ChatView6606App_7b,
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: UserInfo,
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
