<template>
  <Modal
    class-name="sub_modal"
    v-model="showModal"
    :mask-closable="false"
    width="500"
    :footer-hide="true"
  >
    <div class="add_title">申请信息</div>
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="80"
    >
      <FormItem label="手机号" prop="mobile">
        <Input v-model="formValidate.mobile" placeholder="请输入手机号"></Input>
      </FormItem>
      <FormItem label="名称" prop="name">
        <Input v-model="formValidate.name" placeholder="请输入姓名"></Input>
      </FormItem>
      <FormItem label="邮箱" prop="email">
        <Input v-model="formValidate.email" placeholder="请输入邮箱"></Input>
      </FormItem>
      <FormItem label="公司名称" prop="company">
        <Input v-model="formValidate.company" placeholder="请输入公司"></Input>
      </FormItem>
      <FormItem label="部门名称" prop="department">
        <Input
          v-model="formValidate.department"
          placeholder="请输入部门"
        ></Input>
      </FormItem>
      <div class="submit_group">
        <Button type="primary" @click="handleSubmit('formValidate')"
          >提交</Button
        >
        <Button @click="handleReset('formValidate')" style="margin-left: 8px"
          >重置</Button
        >
      </div>
    </Form>
  </Modal>
</template>

<script>
import { editAppRegister, getAppRegisterById } from "@/api/api.js";

import Cookies from "js-cookie";
import { getStore } from "@/libs/storage";
export default {
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      showModal: false,
      ruleSmsValidate: {
        mobile: [
          { required: true, trigger: "blur", message: "请输入手机号" },
          // { validator: checkPhone, trigger: "blur" },
        ],
        name: [{ required: true, trigger: "blur", message: "请输入姓名" }],
        email: [{ required: true, trigger: "blur", message: "请输入邮箱地址" }],
      },
      formValidate: {
        name: "",
        mobile: "",
        company: "",
        email: "",
        department: "",
        username: "",
      },
      ruleValidate: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleSubmit(name) {
      let _this = this;
      let userInfo = Cookies.get("userInfo");
      this.formValidate.username = getStore("uid");
      if (getStore("uid").length < 2) {
        return;
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          let params = this.formValidate;

          // return;
          // 提交数据
          let formData = new FormData();
          formData.append("name", params.name);
          formData.append("mobile", params.mobile);
          formData.append("company", params.company);
          formData.append("department", params.department);
          formData.append("username", getStore("uid"));
          formData.append("email", params.email);
          console.log(params, "params");
          console.log(formData, "formData");
          editAppRegister(formData).then((response) => {
            if (response.success) {
              this.$emit("changeUser", { id: response.result.id });
              this.$Message.success("申请成功");
              this.showModal = false;
            } else {
              this.$Message.error(response.message);
            }
          });
        } else {
          // this.$Message.error("Fail!");
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
  },
  mounted() {
    let userInfo = Cookies.get("userInfo");
    console.log(userInfo,"用户信息")
    if (userInfo) {
      let scd = JSON.parse(userInfo).mobile;
      this.formValidate.mobile = scd;
      this.formValidate.username = getStore("uid");
    }
  },
};
</script>

<style>
.add_title {
  width: 100%;
  font-size: large;
  font-weight: bolder;
  margin-bottom: 0.2rem;
}
.submit_group {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
