const chatDefaultList = [

  {
 ask: "空调系统回风参数温度曲线",
 answer:
"http://101.200.140.188:35479/view1?tagName=R201_SDHX_TT_18.PV",
 type: "html",
 name: "这是一个网页",
  },
  { ask: "LK产品的功能安全性如何？", answer: "LK 产品已通过 CE、UL 认证。为适应特殊环境应用，LK 系列 PLC 采用高端工业级芯片和专业的设计，且模块全部经过严格的专业测试，其中LKS产品具有SIL2等级功能安全特性，并取得了TUV南德功能安全证书；", type: null, name: "文本" },
  { ask: "LK系统安全性具备哪些优势？", answer: "系统可靠性高，具有隔离、自诊断、输出预置功能。", type: null, name: "文本" },
  { ask: "LK系统是否有参考国际标准？", answer: "LK 系列 PLC 充分融合了国际工业电子技术和工业控制技术的最新成果，严格遵循国际先进工业标准，综合体现了离散过程和连续过程自动化的要求，在装备自动化和过程自动化两方面都可以应对自如", type: null, name: "文本" },
  { ask: "LK是否有信息安全特性？", answer: "可信安全 \n\n 2016 年发布 LK220 系列 PLC，为国内首款双机架冗余PLC，  \n\n 同时 LK220 系列 PLC 获得了 Achilles 认证证书，是国内首款获得该认证的大型 PLC", type: null, name: "文本" },
  // { ask: "LK系列PLC三防特性怎么样？", answer: "具有阿基里斯认证。", type: null, name: "文本" },
  { ask: "LK系列PLC三防特性怎么样？", answer: "LK系列PLC是一款具有高端工业级芯片和专业设计的工业自动化控制器，具有极强的稳定性和可靠性。同时，LK全系列PLC出厂时所有板卡涂敷三防漆，实现防潮湿、防烟雾、防霉菌的高防效果，具有G2防腐测试报告能够应对各种恶劣环境条件下的需求。\n\n LK系列PLC已经通过CE认证和UL认证，具备符合国际标准的产品质量和安全性能，确保产品在全球范围内可靠应用。", type: null, name: "文本" },
  { ask: "LK是否具有信息安全性？", answer: "具有信息安全性，可信安全超强的程序保密功能，功能块、程序加密。", type: null, name: "文本" },
  { ask: "LK系列PLC支持什么实时以太网协议？", answer: "支持POWERLINK工业以太网协议，支持环网。 \n\n 集成 2 路 10/100/1000Mbps 以太网接口，用来连接 AT、HMI 或第三方组态软件", type: null, name: "文本" },
  { ask: "LK系列支持的协议有哪些？", answer: "支持ProfiNet协议 \n\n 集成以太网2路 RJ45 接口，10/100M 自适应，双网口冗余，支持 Modbus TCP 协议、HolliTCP 协议", type: null, name: "文本" },
  { ask: "LK210控制器支持POWERLINK协议吗？", answer: "LK210控制器支持POWERLINK工业以太网协议，支持环网；支持ProfiNet协议。 \n\n CPU 模块内置两个 10/100 Mbps 以太网接口，可以作为程序下载口、控制器升级口，也可作为与 Modbus TCP 主 / \n\n 从站的通信接口。面板上的钥匙开关可用来控制 CPU 的工作模式", type: null, name: "文本" },
  { ask: "LK系列PLC是否便捷实用？", answer: "LK系列PLC可靠性高、功能丰富、性能优异、集成度高、扩展性好、体积小巧、易于使用，可为不同工业领域提供个性化的解决方案。", type: null, name: "文本" },
  { ask: "LK易用性具体体现在哪？", answer: "所有模块支持带电热插拔，采用防混销设计； \n\n DP接头可任意连接； \n\n 冗余同步功能无需预设； \n\n 功能丰富、适应面宽、结构紧凑、体积小巧 \n\n 精度高，如采用高细分驱动器可显著提高定位精度，无累积定位误差", type: null, name: "文本" },
  { ask: "LK产品的接线端子与I/O模块是独立设计的吗？", answer: "LK产品的接线端子与I/O模块独立设计、接线端子可插拔。 \n\n LK210系列单机控制系统组成部分分别是： \n\n 1 个本地背板（LK104），包含 1 个 CPU 插槽和 DP 模块插槽 \n\n 1 个单 CPU 模块 \n\n 接口模块（LK232） \n\n I/O模块", type: null, name: "文本" },
  { ask: "LK系列PLC支持哪些以太网协议？", answer: "ModbusTCP主从协议、TCP\IP自由协议、Hlink私有协议、POWERLINK协议、ProfNet主协议、PorfiNet从协议", type: null, name: "文本" },
  { ask: "LK220支持哪些协议？", answer: "LK全系列产品均支持：ModbusTCP主从协议、TCP\IP自由协议、Hlink私有协议、POWERLINK协议、ProfNet主协议、PorfiNet从协议", type: null, name: "文本" },
  { ask: "LK210和LK220是否支持多种协议？", answer: "均可以；支持多种通信协议，多种方式扩展不同类型 I/O 模块 \n\n LK220： \n\n 集成以太网，2 路 RJ45 接口，10/100M 自适应，双网口冗余，支持 Modbus TCP 协议、HolliTCP 协议 \n\n LK210： \n\n 1 路，9 针 D 型连接器（孔型），支持自由口协议、Modbus 主站 / 从站协议 \n\n 1 路，组态选择，端子接线方式，支持自由口协议、Modbus 主站 / 从站协议", type: null, name: "文本" },
  { ask: "LK系列PLC的编程使用什么工具？", answer: "LK系列PLC编程使用Safety FA-AutoThink编程工具，这是一款专为安全型控制系统研发的编程软件，可以完成硬件模块的配置和功能组态，并提供多种组态语言支持，以及多层级密码和数据保护机制，可以保证系统的安全性和稳定性，是一款高效、安全、易用的编程工具。 ", type: null, name: "文本" },
  { ask: "LK系列PLC的编程工具是否是独立开发？", answer: "完全独立自主开发，具有完全知识产权 \n\n 数百个功能安全项目成功实施经验 \n\n 参与功能安全国家标准的编制工作", type: null, name: "文本" },
  { ask: "LK系列控制器编程工具的知识产权归属和利时吗？", answer: "归属，是和利时完全独立自主开发，具有完全知识产权", type: null, name: "文本" },

  { ask: "AutoThink有哪些特点？", answer: "AutoThink具有兼容性好、符合国际标准、安装方便、维护方便、组态简单直观、安全可靠等特点： \n\n 1.兼容性好，支持Windows XP SP3 (32位)、Win7/Win10 (32/64位)等多种操作系统，可以便捷地与不同设备和软件进行交互。\n\n 2.安全可靠，提供多层级密码及数据保护机制，确保系统的稳定性、可靠性和安全性，具有全面的知识产权保护。\n\n 3.符合国际标准，支持标准化的编程语言和规范，如LD、SFC、CFC、ST等，符合IEC61131-3标准。 \n\n 4.操作方便，编程环境支持简体中文、英文，编程指令丰富，支持拖拽方式进行硬件配置、指令调用、通信配置等操作，程序、功能块、函数和编程界面支持复制粘贴。 \n\n 5.组态简单直观，采用树形结构进行硬件配置、任务配置、用户程序、数据等组态，操作界面简单直观。 \n\n 6.维护方便，支持日志功能，记录与控制器通信的相关下装、运行、监视等操作，方便维护人员进行故障排查和维护工作。 \n\n AutoThink可以提供高效、稳定的自动化控制和管理功能，适用于各种工业自动化应用领域。", type: null, name: "文本" },
  // // { ask: "AutoThink安装是否复杂？", answer: "安装方便，操作直观。", type: null, name: "文本" },
  // { ask: "AutoThink是否容易维护？", answer: "维护方便、组态简单直观 \n\n 兼容性好——支持 Windows XP SP3 (32 位 ) ，Win7/Win10 (32/64 位） \n\n 安装方便——安装文件小，仅数十兆，安装速度快 \n\n 维护方便——支持日志功能，记录与控制器通信的相关下装、运行、监视等操作 \n\n 使用便利——编程环境支持简体中文 、英文；编程指令丰富 \n\n 安全可靠——全面的知识产权保护，提供多层级密码及数据保护机制 \n\n 组态简单直观——采用树形结构进行硬件配置、任务配置、用户程序、数据等组态", type: null, name: "文本" },

  { ask: "你好，智工", answer: "你好。 \n\n 智工·工业大模型是工业领域加强大语言模型系统，为您提供专业的工业领域相关知识，可以提供工程设计、实施、运维、生产管控等各维度专业知识。", type: null, name: "文本" },
  { ask: "什么是智工·工业大模型", answer: "智工·工业大模型是由中工互联（北京）科技集团有限公司与复旦大学NLP实验室合作，推出的具有自主知识产权的工业大模型，是大模型技术在工业领域的创新性实践。\n\n 智工·工业大模型将为中国工业领域带来更高效、更智能、更可靠的服务，帮助企业更好地应对生产要素配置、工厂智能管理、节能减碳、安全管理等方面带来的挑战，把握好数字化转型的机遇，实现稳健长远、可持续发展。", type: null, name: "文本" },
  { ask: "智工的特点", answer: " 平台核心 ： \n\n CIIMOSS双擎驱动核心 \n\n 智能决策AI+大模型生成式AI \n\n 关键设计： \n\n 具备工业专业规则、术语等基础知识 \n\n 高质量工业专业语料 \n\n  应用基础： \n\n 中工互联15年工业领域实践经验成果结晶 \n\n 庞大工业实用级知识库储备 \n\n 人性化处理： \n\n 将工业知识（语言）、操作系统（平台）、管理者（人）三者智融合在一起", type: null, name: "文本" },
  { ask: "创造智工的设计团队是谁", answer: " 中工互联科技集团的核心团队 \n\n 公司创始人智振，山东大学计算机&自动化交叉专业毕业，信息产业部电子第六研究所硕士学历，曾就职于信息产业部电子第六研究所、霍尼韦尔（中国） 等工业头部企业，从业20年来，具备数百个工业领域大型信息化项目的实践经验。 \n\n 公司副总经理李延峰，华北电力大学电气自动化硕士学历，曾就职电力自动化巨头四方继保、华电高科等电力信息化企业，20余年工业自动化、电力自动化行业经验，曾主导大型远距离供电项目、电力行业PLC研发项目以及其他电力信息化项目。 \n\n 公司研发技术总监胡志新, 哈尔滨理工大学电气专业,曾就职于第一财经、中国软件网、中国社会扶贫网等大型互联网企业，精通主流开发语言及架构，具备丰富全面的技术背景。 \n\n  公司研发部经理曹健， 郑州工业学院电气工程及其自动化专业、10年以上开发经验，精通工业互联网架构，精通OT&IT融合技术。 \n\n \n\n  外援团队: \n\n 复旦大学NLP实验室， 作为中国最早开展自然语言处理和信息检索研究的实验室之一，在博士生导师张奇教授的带领下，拥有一支充满激情和才华横溢的研究团队,在NLP领域有着丰富的经验和专业知识,借助国际领先的技术优势和研究实力，为中工互联提供先进的自然语言处理解决方案和创新的智能应用。", type: null, name: "文本" },
];




export default chatDefaultList;