<template>
  <div>
    <Drawer class="historyDrawer" :closable="false" width="100%" v-model="showHistoryList">
      <div id="userInfo">
        <div class="userInfo_header">
          <div>
            <img class="returnBack" src="@/assets/back.png" @click="returnChat" alt="返回" />
          </div>
          <div class="userinfo">
            <div>
              <img class="avatar" src="@/assets/logoFav.png" alt="" />
              <div>{{ userInfo.mobile }}</div>
            </div>
            <div @click="exitbtn">退出登录</div>
          </div>
        </div>
        <div class="tips">历史对话</div>
        <div class="userInfo_content" @scroll="handleScroll" ref="scrollHistorys">
          <div :class="current.index == cIndex ? 'chat_history_item chat_history_item_selected' : 'chat_history_item'" @click="showHistory(cItem, cIndex)" @touchstart="startPress" @touchend="endPress" v-for="(cItem, cIndex) in historyList" :key="cIndex">
            <div class="chat_history_item_title">
              <img src="@/assets/talk.png" alt="" />
              {{ getParamValue(cItem, "title") }}
            </div>
            <!-- <img src="../../../assets/export_history.png" class="exportHistory" @click.stop="convertArrayToMd(cItem, cIndex)" title='导出历史' /> -->
            <img class="deleteHistory" src="../../../assets/delete.png" @click.stop="deleteHistory(cItem, cIndex)" />
          </div>
        </div>
        <!-- <div class="userInfo_footer">
          <Button class="newChatBtn" @click="openNewChat">新的对话</Button>
        </div> -->
      </div>
    </Drawer>

    <Drawer class="historyOptions" :closable="false" width="100%" placement="bottom" v-model="historyOptions">
      <div class="historyOptions_content">
        <div class="share">
          <div class="deleteHistory" @click.stop="deleteHistory(cItem, cIndex)">
            <img src="../../../assets/delete.png" />
          </div>
        </div>
        <div class="deleteAll">清除全部</div>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import { Card, Button, Drawer } from "view-design";
  import { getGptHistory, deleteHistory, checkOutToken } from "@/api/api";
  import Cookies from "js-cookie";
  import { getStore, setStore } from "@/libs/storage";
  import FileSaver from "file-saver";
  import wx from "weixin-webview-jssdk";

  export default {
    components: {
      Card,
      Button,
      Drawer,
    },
    data() {
      return {
        historyList: [],
        current: {
          index: null,
        },
        userInfo: {
          mobile: "",
        },
        showHistoryList: false,
        historyOptions: false,
        hasMore: false, // 是否还有更多数据
        page: 1, // 当前页码
        pageSize: 20, // 每页显示数量
        timer: null, // 用于保存计时器的引用
      };
    },
    methods: {
      initHistory() {
        this.historyList = [];
        // this.current.index = null;
        this.hasMore = false;
        this.page = 1;
        this.pageSize = 20;
        this.getGptHistory();
      },
      openNewChat() {
        if (this.historyList.length == 0 || this.historyList[0].historys.length == 0) {
          this.$Message.info("当前已是新的对话");
        } else {
          console;
          this.historyList.unshift({
            id: "newchat",
            historys: [],
          });
          this.current = {
            index: 0,
          };
          this.$emit("closeHistory", { name: "historyList", value: [] });
          // this.$emit("closeHistory", { name: "showHistory", value: false });
          this.showHistoryList = false;
        }
        // 触发父组件的清空方法，回到defaultList；
      },
      showHistory(data, index) {
        this.current.index = index;
        // 处理好数据后，添加到父组件的 currentListHistory
        let array = [];
        data.historys.forEach((element) => {
          array.push({
            question: element.question,
            answer: element.answer,
            status: 2,
            agree: false,
            disagree: false,
            // speak: false,
            // pause: false,
            loading: false,
            time: this.protoTime(element.time),
          });
        });
        this.$emit("closeHistory", { name: "historyList", value: array });
        this.$emit("closeHistory", { name: "currentId", value: data.id });
        // this.$emit("closeHistory", { name: "showHistory", value: false });
        this.showHistoryList = false;
        //   this.$emit("setCurrentListHistory", array);
      },

      // 删除聊天记录
      deleteHistory(data, index) {
        this.showHistory(data, index);
        // data.id  走接口
        this.$Modal.confirm({
          title: "删除",
          content: `<p>确认删除此条记录？</p>`,
          loading: true,
          onOk: () => {
            let params = { id: data.id };
            deleteHistory(params).then((res) => {
              if (res.success) {
                setTimeout(() => {
                  this.$Modal.remove();
                  this.$Message.info(res.message);
                  this.openNewChat();
                  this.initHistory();
                }, 1000);
              }
            });
          },
        });
      },
      // 获取title
      getParamValue(data, type) {
        switch (type) {
          case "title":
            if (data.historys.length > 0) return data.historys[0]["question"];
            else return "新的对话";
          case "time":
            if (data.historys.length > 0) return this.protoTime(data.historys[0]["time"]);
            else return this.protoTime(new Date().valueOf());
          case "pice":
            if (data.historys) return data.historys.length;
            else return 0;
          default:
            break;
        }
      },

      // 查询历史记录
      getGptHistory() {
        let params = {
          order: "desc",
          pageNumber: this.page,
          pageSize: this.pageSize,
        };
        getGptHistory(params).then((res) => {
          if (res.success) {
            if (res.result.content !== undefined && res.result.content.length >= 20) {
              this.page = this.page + 1;
              this.hasMore = true;
            } else {
              this.hasMore = false;
            }
            let array = [];
            res.result.content.forEach((item) => {
              array.push({
                id: item.id,
                historys: JSON.parse(item.historys),
              });
            });
            this.historyList = this.historyList.concat(array);
          }
        });
      },
      // 时间戳转 标准时间
      protoTime(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);

        const standardTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        return standardTime;
      },
      // 退出登录
      exitbtn(v) {
        let userInfo = Cookies.get("userInfo");
        checkOutToken(`${JSON.parse(userInfo).accessToken}/${getStore("cur")}`)
          // fetch(
          //   `http://101.200.140.188:38892/check_out/${
          //     JSON.parse(userInfo).accessToken
          //   }/${getStore("cur")}`
          // )
          .then((response) => {
            // infoRes.json().then((response) => {
            console.log("退出登录");
            this.flag = !this.flag;
            this.vip = "";
            // this.isId = "";
            Cookies.remove("userInfo");
            Cookies.set("accessToken", "");
            setStore("vip", "");
            setStore("uid", "");
            setStore("accessToken", "");
            wx.miniProgram.navigateTo({
              url: "/pages/login_wx/login_wx?relogin=true",
            });
            // this.$router.push("/");
            // });
          });
      },
      returnChat() {
        // this.$emit("closeHistory", { name: "showHistory", value: false });
        this.showHistoryList = false;
      },
      handleScroll() {
        const container = this.$refs.scrollHistorys;
        const scrollTop = container.scrollTop;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;
        // 判断是否滚动到底部
        if (scrollTop + containerHeight >= scrollHeight) {
          this.loadMore();
        }
      },
      loadMore() {
        if (this.hasMore) {
          this.getGptHistory();
        }
      },
      startPress() {
        this.timer = setTimeout(() => {
          // 长按事件触发的操作
          // this.historyOptions = true;
        }, 1000); // 设置长按时间阈值，这里设置为1秒（1000毫秒）
      },
      endPress() {
        clearTimeout(this.timer); // 清除计时器
      },
      // 导出md文件
      convertArrayToMd(chatHistory) {
        let mdContent = "# 对话记录\n\n";
        chatHistory.historys.forEach((chat) => {
          mdContent += `Role: ${chat.question}\n\nZhiGong: ${chat.answer}\n\n`;
        });
        const blob = new Blob([mdContent], { type: "text/plain;charset=utf-8" });
        let time = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay()}_${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
        FileSaver.saveAs(blob, `ZhiGong${time}.md`);
        // this.$Message.success("导出成功")
      },
    },
    mounted() {
      let userInfo = Cookies.get("userInfo");
      if (!userInfo) {
        this.$Message.error("请先登录");
        setTimeout(() => {
          this.$router.push("/webapp");
        }, 1000);
        return;
      } else {
        this.userInfo.mobile = JSON.parse(userInfo).mobile;
      }
      this.getGptHistory();
    },
  };
</script>

<style lang="less" scoped>
  #userInfo {
    background: linear-gradient(135deg, #f0f1f5 0%, #d8dff0 100%);
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 20px;
  }
  .userInfo_header {
    height: 180px;
    .returnBack {
      width: 30px;
      height: 30px;
    }
    .userinfo {
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      img {
        margin-right: 20px;
      }
      div {
        display: flex;
        flex-direction: row;
        font-weight: 600;
      }
    }
  }
  .userInfo_content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    .chat_history_list {
      // height: 80vh;
      overflow-y: auto;
    }
    .chat_history_item {
      padding: 10px;
      // background-color: #cfcfcf;
      margin: 5px 0;
      border-radius: 10px;
      // height: 70px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      cursor: pointer;

      .chat_history_item_title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        img {
          width: 18px;
        }
      }
      p {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .deleteHistory {
        border-radius: 50% 50%;
        background-color: #fff;
        position: absolute;
        right: 10px;
        // bottom: 10px;
        width: 20px;
        display: none;
        img {
          // position: absolute;
          width: 100%;
          height: 100%;
        }
      }
      .exportHistory {
        border-radius: 50% 50%;
        background-color: #fff;
        position: absolute;
        right: 30px;
        // bottom: 10px;
        width: 20px;
        display: none;
        img {
          // position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
    .chat_history_item:hover {
      .deleteHistory {
        display: block;
      }
      .exportHistory {
        display: block;
      }
    }
    .chat_history_item_selected {
      // border: 2px solid #4773ee;
      background-color: #ffffffdc;
    }
    .chat_history_list_footer {
      padding-top: 20px;
    }
    .newChatBtn {
      width: 100%;
      min-height: 50px;
      font-size: 16px;
      background-image: linear-gradient(-56deg, #0773ff 5%, #797eff);
      color: #fff;
      font-weight: bold;
    }
  }
  .userInfo_footer {
    width: 100%;
    height: 100px;

    .newChatBtn {
      width: 100%;
      height: 50px;
      color: #ffffff;
      background-image: linear-gradient(139deg, rgb(195, 160, 255), #6d8bff);
      font-size: 14px;
      font-weight: bold;
      margin-top: 20px;
      border-radius: 7px;
    }
  }
  .historyOptions {
    .historyOptions_content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }
    .share {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      img {
        width: 40px;
      }
    }
  }
  .tips {
    text-align: center;
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    // color: #ffffff;
    // background-image: linear-gradient(139deg, #d2d2db, #cfd0d4);
    font-size: 16px;
    font-weight: bold;
    // margin-top: 20px;
    // border-radius: 7px;
    text-align: center;
    line-height: 60px;
  }
</style>
